<!-- @format -->

<template>
  <div>
    <div class="flex flex-row items-center justify-center mt-2">
      <a
        :href="$config.facebook.link"
        class="mr-1 lg:fluid-type-nfpsocial"
        target="_blank"
      >
        <font-awesome-icon
          :icon="['fab', 'facebook-square']"
          class="mb-1 mr-1"
        />
      </a>
      <a
        :href="$config.youtube.link"
        class="mr-1 lg:fluid-type-nfpsocial"
        target="_blank"
      >
        <font-awesome-icon
          :icon="['fab', 'youtube-square']"
          class="mb-1 mr-1"
        />
      </a>
      <a
        :href="$config.instagram.link"
        class="mr-1 lg:fluid-type-nfpsocial"
        target="_blank"
      >
        <font-awesome-icon :icon="['fab', 'instagram']" class="mb-1" />
      </a>
    </div>
  </div>
</template>

<script>
  export default {}
</script>
